
import React, { useState } from 'react'
import Layout from '../../components/layout'
import styled, { keyframes } from 'styled-components'
import { Container, Typography, Grid, Paper } from '@material-ui/core'
import { Colors } from '../../constants'
import Particles from './_particles'
import { EmojiObjects, Code, Build } from '@material-ui/icons'
import Fade from 'react-reveal/Fade';
import Ticker from 'react-ticker'

import PageVisibility from 'react-page-visibility'

const DiscoverImage = require('../../dist/images/design.svg');
const DevelopImage = require('../../dist/images/connect.svg');
const BugfixImage = require('../../dist/images/bugfix.svg');


export default () => {
    const [pageIsVisible, setPageIsVisible] = useState(true)

    const handleVisibilityChange = (isVisible) => {
        setPageIsVisible(isVisible)
    }
    return (
        <Layout padTop={true} title={'Enterprise Web Applications'} description={'We leverage the agile methodology in conjunction with the latest technologies to ensure every application is secure, scalable and delivered on time. Regardless of project size, we strive to deliver excellence without breaking your budget.'}>
            <Banner>
                <Particles />
                <BannerTextContainer>
                    <div style={{position: 'absolute', bottom: '25px', left: '0', width: '100%'}}>
                        <Container style={{position: 'relative'}}>
                            <div style={{maxWidth: '550px'}}>
                                <BannerTextMain variant={'button'}>Enterprise Web Applications</BannerTextMain>
                            </div>                                
                        </Container>      
                    </div>     
                </BannerTextContainer>
            </Banner>
            <div style={{background: '#F9F9F9'}}>
                <Container style={{paddingTop: '25px', paddingBottom: '25px', textAlign: 'center'}}>
                    <InfoHeader variant={'button'}>
                        Software solutions that scale
                    </InfoHeader>
                    <InfoBody>
                        We leverage the agile methodology in conjunction with the latest technologies to 
                        ensure every application is secure, scalable and delivered on time. Regardless of 
                        project size, we strive to deliver excellence without breaking your budget.
                    </InfoBody>
                </Container>
            </div>
            <PageInformation>
                <ProcessDiagramContainer>
                    <ProcessDiagramLinesWrapper> 
                    <PageVisibility onChange={handleVisibilityChange}>
                        {
                        pageIsVisible && <Ticker mode={'chain'} speed={10} offset={1000} direction={'toRight'}>
                            {
                                () =>   (
                                
                                    <p style={{ whiteSpace: "nowrap" }}>
                                        <svg height="80" width="25px">
                                            <g fill="none" stroke="black" stroke-width="4">
                                                <KeyframesPath d="M5 40 l2150 0" />
                                            </g> 
                                        </svg>
                                        
                                    </p>                                   
                                )                                           
                            
                            }
                       
                        </Ticker>
                         }  
                    </PageVisibility>
                            
                    </ProcessDiagramLinesWrapper>
                    <ProcessSection>
                        <ProcessNumber><EmojiObjects className={'child'}/></ProcessNumber>
                        <ProcessInformationWrapper>
                            <ProcessInformation>Discover</ProcessInformation>
                        </ProcessInformationWrapper>
                    </ProcessSection>
                    <ProcessSection>
                        <ProcessNumber><Code className={'child'}/></ProcessNumber>
                        <ProcessInformationWrapper>
                            <ProcessInformation>Engineer</ProcessInformation>
                        </ProcessInformationWrapper>
                    </ProcessSection>
                    <ProcessSection>
                        <ProcessNumber><Build className={'child'}/></ProcessNumber>
                        <ProcessInformationWrapper>
                            <ProcessInformation>Maintain</ProcessInformation>
                        </ProcessInformationWrapper>
                    </ProcessSection>
                </ProcessDiagramContainer>
            </PageInformation>
            
            <Separater />

            <Container>
                <Fade top>
                    <Section>
                        <SectionHeader>
                            <SectionImage src={'discover'} />                      
                        </SectionHeader>

                        <SectionBody>
                            <InfoHeader>NEED HELP GETTING STARTED?</InfoHeader>
                            <Divider />
                            <InfoBody>
                            {/* <InfoBodyItem>Our team of project managers will help categorize each business requirement in order to organize them into an easy to understand project timeline.</InfoBodyItem> */}
                                <InfoBodyItem>We offer the perspective and experience to set you in the right direction.</InfoBodyItem>
                                <ul style={{paddingLeft: '25px'}}>
                                    <li>
                                        <InfoBodyItem>
                                            Our team of project managers will deconstruct your business requirements to produce a tangible, descriptive project timeline.
                                        </InfoBodyItem>
                                    </li>
                                    <li>
                                        <InfoBodyItem>
                                            Our designers will construct a streamlined workflow for your users. We analyze each business requirement in great detail in order to provide you with 
                                            a discovery packet that includes a full project report, mockups, designs, architecture diagrams, and project timeline.
                                        </InfoBodyItem>
                                    </li>
                                    
                                </ul>
                            </InfoBody>
                        </SectionBody>
                    </Section>
                    <Section>
                        <SectionHeader>
                            <SectionImage src={'develop'} />                      
                        </SectionHeader>

                        <SectionBody>
                        <InfoHeader>NEED A PROJECT ENGINEERED?</InfoHeader>
                        <Divider />
                            <InfoBody>
                            <InfoBodyItem>Our dedicated engineers are here to help. </InfoBodyItem>
                                <ul style={{paddingLeft: '25px'}}>
                                    <li>
                                        <InfoBodyItem>
                                            You can rely on streamlined communication between your team and ours, leaving no project detail unanalyzed.
                                        </InfoBodyItem>
                                    </li>
                                    <li>
                                        <InfoBodyItem>
                                            Our project managers will work closely with all stakeholders to build a reasonable timeline, and offer bi-weekly, 
                                            incremental releases for stakeholders to review and provide feedback.
                                        </InfoBodyItem>
                                    </li>
                                    <li>
                                        <InfoBodyItem>
                                            From project conception to production, our services are available for all stages of development.
                                        </InfoBodyItem>
                                    </li>
                                </ul>                            
                            </InfoBody>
                        </SectionBody>
                        
                    </Section>
                    <Section>
                        <SectionHeader>
                            <SectionImage src={'bugfix'} />                      
                        </SectionHeader>

                        <SectionBody>
                            <InfoHeader>ONGOING SUPPORT</InfoHeader>
                            <Divider />
                            <InfoBody>
                                <InfoBodyItem>We are eager for your product to succeed, even after development.</InfoBodyItem>
                                    <ul style={{paddingLeft: '25px'}}>
                                        <li>
                                            <InfoBodyItem>
                                                We offer on-call support services for any bugs or issues encountered through the initial stages of project release, and beyond.
                                            </InfoBodyItem>
                                        </li>
                                        <li>
                                            <InfoBodyItem>
                                                All reported issues return a full Root Cause Analysis (RCA). Our tech op specialists will locate and document the root cause of your 
                                                bug or issue, after which, is monitoried to ensure correction.
                                            </InfoBodyItem>
                                        </li>
                                    </ul> 
                            </InfoBody>
                        </SectionBody>
                    </Section>
                </Fade>
            </Container>
        </Layout>
    )
}

// const ContactWrapper = styled.div`
//     background: #F9F9F9;
//     padding: 25px;
// `;
const Divider = styled.div`
    margin-top: 5px;
    width: 40px;
    height: 3px;
    background-color: ${Colors.auxidusBlue};
    opacity: 0.7;
`;
const Section = styled.div`
    position: relative;
    display: -ms-grid;
    display: grid;
    grid-template-columns: ${props => props.type === 'alt' ? 'calc(100% - 300px) 300px' : '300px calc(100% - 300px)'};
    grid-template-gap: 1%;
    min-height: 300px;
    height: 100%;
    @media only screen and (max-width: 800px) {
        min-height: 0px;
        display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
    }
    margin: 50px auto;
`;
const SectionBody = styled.div`
    background-color: #FFFFFF;
    display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
    flex-direction: column;
    margin-left: 50px;
    text-align: left;
    padding-left: ${props => props.type === 'alt' ? '0' : '50px'};
    padding-right: ${props => props.type === 'alt' ? '50px' : '0'};
    @media only screen and (max-width: 800px) {
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
    }
`;
const SectionImage = styled.div`
    background: ${props => props.src === 'discover' ? `url(${DiscoverImage}) no-repeat center center` : props.src === 'develop' ? `url(${DevelopImage}) no-repeat center center` : `url(${BugfixImage}) no-repeat center center`}; 
    background-size: contain;
    min-height: 300px;
    height: 100%;
    width: 300px;
    position: relative;
    margin: auto;
    @media only screen and (max-width: 800px) {
        height: 0;
        width: 0;
        display: none;
    }
`;
const SectionHeader = styled.div`
    // border: 1px solid ${Colors.auxidusBlue};
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
`;

const InfoHeader = styled(Typography)`
    font-size: calc(15px + 4 * ((100vw - 185px) / 640));
    font-weight: bold;
    color: black;
`;
const InfoBody = styled(Typography)`
    margin-top: 25px;
    font-size: calc(13px + 2 * ((100vw - 185px) / 640));
    color: ${Colors.textSecondaryGray};
`;

const InfoBodyItem = styled.div`
    padding: 5px;
`;

const Separater = styled.div`
    background-color: #fefefe;
    background-image: url("data:image/svg+xml,%3Csvg width='40' height='12' viewBox='0 0 40 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 6.172L6.172 0h5.656L0 11.828V6.172zm40 5.656L28.172 0h5.656L40 6.172v5.656zM6.172 12l12-12h3.656l12 12h-5.656L20 3.828 11.828 12H6.172zm12 0L20 10.172 21.828 12h-3.656z' fill='%23666666' fill-opacity='0.03' fill-rule='evenodd'/%3E%3C/svg%3E");
    height: 10px;
    width: 100%;
`;
const ProcessInformation = styled(Typography)`
    font-size: calc(14px + 2 * ((100vw - 185px) / 640));
    color: ${Colors.auxidusBlue};
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em
`
const ProcessInformationWrapper = styled.div`
    width: calc(100% - 30px);
    overflow: normal;
    overflow-wrap: anywhere;
    margin-top: 25px;
    text-align: center;
    height: 100%;
    padding: auto 10px;
`;
const PathKeyframes = keyframes`
    to {
        stroke-dashoffset: -100;
    }
`;
const KeyframesPath = styled.path`
    // -webkit-animation: ${PathKeyframes} 3s linear infinite;
    // -moz-animation: ${PathKeyframes} 3s linear infinite;
    // -ms-animation: ${PathKeyframes} 3s linear infinite;
    // -o-animation: ${PathKeyframes} 3s linear infinite;
    // animation: ${PathKeyframes} 3s linear infinite;
    // animation-delay: -1s;
    stroke: ${Colors.auxidusBlue};
`;
const ProcessDiagramLinesWrapper = styled.div`
    position: absolute;
    width: 65%;
    left: 19%;
    overflow: hidden;
    place-self: center;
    @media only screen and (min-width: 0px) {
        padding-bottom: 60px;
    }
    @media only screen and (min-width: 338px) {
        padding-bottom: 45px;
    }
    @media only screen and (min-width: 1024px) {
        padding-bottom: 45.5px;
    }
    @media only screen and (min-width: 1170px) {
        padding-bottom: 45px;
    }
    @media only screen and (min-width: 1752px) {
        padding-bottom: 50px;
    }

`;
const ProcessNumber = styled.div`
    background: ${Colors.auxidusBlue};
    color: #f3f3f3;
    display: -webkit-box;
    height: calc(22px + 7 * ((100vw - 185px) / 640));
    width: calc(22px + 7 * ((100vw - 185px) / 640));
    padding: 1.35vw;
    flex: none;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: relative;
    .child {
        height: calc(14px + 7 * ((100vw - 185px) / 640));
        width: calc(14px + 5 * ((100vw - 185px) / 640));
        
        position: absolute;
        opacity: 0.8;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        -moz-transform: translate(-50%, -50%) rotate(-45deg);
        -o-transform: translate(-50%, -50%) rotate(-45deg);
        -ms-transform: translate(-50%, -50%) rotate(-45deg);
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`;
const ProcessDiagramContainer = styled(Container)`
    width: 100%;
    padding: 0;
    position: relative;
    display: -ms-grid;
    display: grid;
    grid-template-columns: 33% 33% 33%;
`;
const ProcessSection = styled.div`
    width: 100%;
    height: 100%;
    display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Banner = styled.div`
    width: 100%;
    height: 20vmax;
    position: relative;
    background-color: #F9F9F9;
    border-bottom: 2px solid ${Colors.auxidusBlue};
`;
const BannerTextContainer = styled.div`
    position: absolute;
    left: 0; right: 0;
    bottom: 0;
    width: 100%;
    height: 250px;
`;
const BannerTextMain = styled(Typography)`
    font-size: calc(22px + 4 * ((100vw - 185px) / 380));
    line-height: calc(22px + 6 * ((100vw - 185px) / 380));
    font-weight: bold;
    color: ${Colors.backgroundBlueLighter};
    
`;
const PageInformation = styled.div`
    width: 100%;
    background-color: #F9F9F9;
    color: lightgrey;
    position: relative;
    padding: 25px 0px;
`;